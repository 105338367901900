(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.ba.as === region.bm.as)
	{
		return 'on line ' + region.ba.as;
	}
	return 'on lines ' + region.ba.as + ' through ' + region.bm.as;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList === 'function' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cU,
		impl.dL,
		impl.dr,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		Q: func(record.Q),
		bb: record.bb,
		a5: record.a5
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.Q;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.bb;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.a5) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cU,
		impl.dL,
		impl.dr,
		function(sendToApp, initialModel) {
			var view = impl.dM;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cU,
		impl.dL,
		impl.dr,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.a8 && impl.a8(sendToApp)
			var view = impl.dM;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.cl);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.d) && (_VirtualDom_doc.title = title = doc.d);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.c4;
	var onUrlRequest = impl.c5;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		a8: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.bM === next.bM
							&& curr.bt === next.bt
							&& curr.bJ.a === next.bJ.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		cU: function(flags)
		{
			return A3(impl.cU, flags, _Browser_getUrl(), key);
		},
		dM: impl.dM,
		dL: impl.dL,
		dr: impl.dr
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { cM: 'hidden', cu: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { cM: 'mozHidden', cu: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { cM: 'msHidden', cu: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { cM: 'webkitHidden', cu: 'webkitvisibilitychange' }
		: { cM: 'hidden', cu: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		bS: _Browser_getScene(),
		b_: {
			aT: _Browser_window.pageXOffset,
			aU: _Browser_window.pageYOffset,
			ap: _Browser_doc.documentElement.clientWidth,
			ai: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		ap: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		ai: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			bS: {
				ap: node.scrollWidth,
				ai: node.scrollHeight
			},
			b_: {
				aT: node.scrollLeft,
				aU: node.scrollTop,
				ap: node.clientWidth,
				ai: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			bS: _Browser_getScene(),
			b_: {
				aT: x,
				aU: y,
				ap: _Browser_doc.documentElement.clientWidth,
				ai: _Browser_doc.documentElement.clientHeight
			},
			cD: {
				aT: x + rect.left,
				aU: y + rect.top,
				ap: rect.width,
				ai: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.j) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.m),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.m);
		} else {
			var treeLen = builder.j * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.n) : builder.n;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.j);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.m) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.m);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{n: nodeList, j: (len / $elm$core$Array$branchFactor) | 0, m: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {bo: fragment, bt: host, bH: path, bJ: port_, bM: protocol, bN: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$document = _Browser_document;
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Main$init = _Utils_Tuple2(
	{},
	$elm$core$Platform$Cmd$none);
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Main$update = F2(
	function (msg, model) {
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $elm$html$Html$a = _VirtualDom_node('a');
var $author$project$Data$aboutInfo = {cR: 'Angela Y. Davis, Frank Chapman, and Rasmea Odeh', a: '/images/aboutBg.jpg', dt: 'The Refounding Conference of the National Alliance Against Racist & Political Repression will take place in Chicago, Illinois on November 22-24. Activists and organizers from across the country will gather to refound NAARPR and coordinate nation-wide actions against state sponsored racial violence.'};
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Main$insertLink = F3(
	function (linkText, linkTarget, string) {
		return A2(
			$elm$core$List$intersperse,
			A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href(linkTarget)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(linkText)
					])),
			A2(
				$elm$core$List$map,
				$elm$html$Html$text,
				A2($elm$core$String$split, linkText, string)));
	});
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$html$Html$section = _VirtualDom_node('section');
var $author$project$Data$siteInfo = {cN: 'https://naarpr.org', ds: 'On November 22-24, we will gather in Chicago, Illinois to refound the National Alliance and bring the fight for #CommunityControlNow to cities across the country.', d: 'NAARPR Conference'};
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Main$aboutSection = A2(
	$elm$html$Html$section,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$id('about'),
			$elm$html$Html$Attributes$class('dark')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('About')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src($author$project$Data$aboutInfo.a),
								$elm$html$Html$Attributes$alt($author$project$Data$aboutInfo.cR)
							]),
						_List_Nil)
					]),
				A3($author$project$Main$insertLink, 'National Alliance Against Racist & Political Repression', $author$project$Data$siteInfo.cN, $author$project$Data$aboutInfo.dt))),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('For press inquiries, please visit: '),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('https://naarpr.org/media/')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('https://naarpr.org/media')
						]))
				]))
		]));
var $author$project$Data$agenda = _List_fromArray(
	[
		{
		a_: 'Friday, November 22',
		a9: _List_fromArray(
			[
				{
				o: _List_fromArray(
					[
						{
						e: {h: $elm$core$Maybe$Nothing, i: $elm$core$Maybe$Nothing},
						f: '',
						g: '',
						d: 'Doors Open'
					}
					]),
				p: '6:00 PM',
				q: ''
			},
				{
				o: _List_fromArray(
					[
						{
						e: {h: $elm$core$Maybe$Nothing, i: $elm$core$Maybe$Nothing},
						f: 'Defending Democracy and Community Control of the Police. Keynote address by Angela Y. Davis.',
						g: 'Main Hall',
						d: 'Rally On Human Rights'
					}
					]),
				p: '7:00 PM',
				q: ''
			}
			])
	},
		{
		a_: 'Saturday, November 23',
		a9: _List_fromArray(
			[
				{
				o: _List_fromArray(
					[
						{
						e: {h: $elm$core$Maybe$Nothing, i: $elm$core$Maybe$Nothing},
						f: 'Registration and continental breakfast.',
						g: 'Main Hall',
						d: 'Doors Open'
					}
					]),
				p: '8:30 AM',
				q: ''
			},
				{
				o: _List_fromArray(
					[
						{
						e: {
							h: $elm$core$Maybe$Nothing,
							i: $elm$core$Maybe$Just(
								_List_fromArray(
									['Christel Williams-Hayes']))
						},
						f: 'Opening Resolution: Refound the National Alliance Against Racist and Political Repression!',
						g: 'Main Hall',
						d: 'Welcome'
					}
					]),
				p: '9:30 AM',
				q: ''
			},
				{
				o: _List_fromArray(
					[
						{
						e: {
							h: $elm$core$Maybe$Just(
								_List_fromArray(
									['Loretta VanPelt'])),
							i: $elm$core$Maybe$Just(
								_List_fromArray(
									['Frank Chapman', 'Angela Davis', 'Mike Sampson', 'Tanya Watkins']))
						},
						f: 'This plenary will educate explain the need for a movement for community control of the police as well as\nending mass incarceration in the United States. Audience members will learn about the historical demand for\ncommunity control of the police, it’s origins and why the movement to end police crimes must fight for\ncommunity control of the people.',
						g: 'Main Hall',
						d: '[PANEL] The struggle for police accountability in a prison nation must be based on a strategy for community control of the police.'
					}
					]),
				p: '10:00 AM',
				q: 'Plenary'
			},
				{
				o: _List_fromArray(
					[
						{
						e: {
							h: $elm$core$Maybe$Just(
								_List_fromArray(
									['Ariel Atkins'])),
							i: $elm$core$Maybe$Just(
								_List_fromArray(
									['Danya Zituni', 'Ralph Poynter', 'Cherrene Horazuk', 'Masao Suzuki', 'Mildred Williamson', 'Emma Lozano']))
						},
						f: 'A panel of organizers from oppressed nationality and labor movements will discuss how the government has\nsuppressed the people\'s movements, and how the movements - including the National Alliance since its\nfounding - are fighting back.',
						g: 'Main Hall',
						d: '[PANEL] People\'s Movements Under Attack: Fighting Back'
					}
					]),
				p: '11:00 AM',
				q: 'Plenary'
			},
				{
				o: _List_fromArray(
					[
						{
						e: {h: $elm$core$Maybe$Nothing, i: $elm$core$Maybe$Nothing},
						f: '',
						g: '',
						d: 'Lunch'
					}
					]),
				p: '12:00 PM',
				q: ''
			},
				{
				o: _List_fromArray(
					[
						{
						e: {
							h: $elm$core$Maybe$Just(
								_List_fromArray(
									['Michael Sampson', 'Sydney Loving'])),
							i: $elm$core$Maybe$Nothing
						},
						f: 'The purpose of this workshop is to share experiences of organizing for community control of the police\ngrounded in the practical organizing experience of campaigns from different cities. This workshop is an\nopportunity for groups to exchange ideas on how to do better their local police accountability work while\ndrawing lessons from existing movements.',
						g: '',
						d: '[WORKSHOP] How to build for community control of the police: Lessons and Strategies from the movement.'
					},
						{
						e: {
							h: $elm$core$Maybe$Just(
								_List_fromArray(
									['hondo t’chikwa', 'Jess Sundin'])),
							i: $elm$core$Maybe$Just(
								_List_fromArray(
									['Devin Branch', 'Betty Davis', 'Masai Ehehosi', 'LaTanya Jenifor-Sublett', 'Bassem Kawar', 'Carlos Montes', 'Rhonda Ramiro', 'Tracy Molm', 'Sheridan Murphy', 'Regina Russell']))
						},
						f: 'A panel of former political prisoners, grand jury resisters, and those working in their defense will speak about\ntheir experiences. Join the fight to free political prisoners and prisoners of war, and discuss how we can resist\ngovernment attacks on our movements today.',
						g: '',
						d: '[WORKSHOP] It is our duty to fight for our freedom: The fight to free political prisoners and the wrongfully-convicted, and to resist political repression'
					},
						{
						e: {
							h: $elm$core$Maybe$Just(
								_List_fromArray(
									['Christina Kittle'])),
							i: $elm$core$Maybe$Just(
								_List_fromArray(
									['CeCe McDonald', 'Kent Marrero', 'Stephanie Skora', 'Mia Whatley', 'more TBA']))
						},
						f: 'This workshop will focus on different struggles such as the movement against white supremacy/white\nnationalism along with the struggle against violence imposed on the LGBTQI, specifically the violence against\ntranswomen of color. This workshop as well will uplift struggles against legislation police policies which\ncontribute to racist and anti-queer violence. It will also highlight how capitalism’s creation of gender roles has\nled to the police upholding a structure that targets any individuals living outside of those strict gender roles as\ncriminal or deviant.',
						g: '',
						d: '[WORKSHOP] The fight against racist and anti-LGBT/Queer violence.'
					},
						{
						e: {
							h: $elm$core$Maybe$Just(
								_List_fromArray(
									['Aislinn Pulley', 'Regina Russell', 'Jess Sundin'])),
							i: $elm$core$Maybe$Just(
								_List_fromArray(
									['Dinni Aden', 'Sumaya Aden', 'Monique Cullars Doty', 'Bertha Escamilla', 'Toshira Garraway', 'Kimberly Handy-Jones', 'Dorothy Holmes', 'Esther Hernandez', 'Shirley McDaniels', 'Marvin Oliveros', 'Arewa Karen Winters', 'others TBA']))
						},
						f: 'Families will share their loved ones’ stories, then discuss with fellow activists will address how they’ve worked\ntogether to fight for justice. Attendees should bring questions and ideas for how to best support families and\nbuild their activism.',
						g: '',
						d: '[WORKSHOP] Families to the front! Families as leaders in the fight against police murder and unjust incarceration'
					}
					]),
				p: '1:00 PM',
				q: 'Breakout Session'
			},
				{
				o: _List_fromArray(
					[
						{
						e: {h: $elm$core$Maybe$Nothing, i: $elm$core$Maybe$Nothing},
						f: '',
						g: '',
						d: 'Break'
					}
					]),
				p: '2:30 PM',
				q: ''
			},
				{
				o: _List_fromArray(
					[
						{
						e: {
							h: $elm$core$Maybe$Just(
								_List_fromArray(
									['Michael Sampson', 'Sydney Loving'])),
							i: $elm$core$Maybe$Nothing
						},
						f: 'The purpose of this workshop is to share experiences of organizing for community control of the police\ngrounded in the practical organizing experience of campaigns from different cities. This workshop is an\nopportunity for groups to exchange ideas on how to do better their local police accountability work while\ndrawing lessons from existing movements.',
						g: '',
						d: '[WORKSHOP] How to build for community control of the police: Lessons and Strategies from the movement.'
					},
						{
						e: {
							h: $elm$core$Maybe$Just(
								_List_fromArray(
									['hondo t’chikwa', 'Jess Sundin'])),
							i: $elm$core$Maybe$Just(
								_List_fromArray(
									['Devin Branch', 'Betty Davis', 'Masai Ehehosi', 'LaTanya Jenifor-Sublett', 'Bassem Kawar', 'Carlos Montes', 'Rhonda Ramiro', 'Tracy Molm', 'Sheridan Murphy', 'Regina Russell']))
						},
						f: 'A panel of former political prisoners, grand jury resisters, and those working in their defense will speak about\ntheir experiences. Join the fight to free political prisoners and prisoners of war, and discuss how we can resist\ngovernment attacks on our movements today.',
						g: '',
						d: '[WORKSHOP] It is our duty to fight for our freedom: The fight to free political prisoners and the wrongfully-convicted, and to resist political repression'
					},
						{
						e: {
							h: $elm$core$Maybe$Just(
								_List_fromArray(
									['Masao Suzuki'])),
							i: $elm$core$Maybe$Just(
								_List_fromArray(
									['Emma Lozano', 'Muhammad Sankari', 'Sue Udry', 'a speaker from the Minnesota Immigrants Rights Action Committee (MIRAC)']))
						},
						f: 'Discuss how the Federal government uses local police to carry out repression against immigrants, Arabs,\nMuslims, and others and ways that communities have resisted.',
						g: '',
						d: '[WORKSHOP] Opposing police cooperation with Federal agencies to oppress our communities'
					},
						{
						e: {
							h: $elm$core$Maybe$Just(
								_List_fromArray(
									['Aislinn Pulley', 'Regina Russell', 'Jess Sundin'])),
							i: $elm$core$Maybe$Just(
								_List_fromArray(
									['Dinni Aden', 'Sumaya Aden', 'Monique Cullars Doty', 'Bertha Escamilla', 'Toshira Garraway', 'Kimberly Handy-Jones', 'Dorothy Holmes', 'Esther Hernandez', 'Shirley McDaniels', 'Marvin Oliveros', 'Arewa Karen Winters', 'others TBA']))
						},
						f: 'Families will share their loved ones’ stories, then discuss with fellow activists will address how they’ve worked\ntogether to fight for justice. Attendees should bring questions and ideas for how to best support families and\nbuild their activism.',
						g: '',
						d: '[WORKSHOP] Families to the front! Families as leaders in the fight against police murder and unjust incarceration'
					}
					]),
				p: '2:45 PM',
				q: 'Breakout Session'
			},
				{
				o: _List_fromArray(
					[
						{
						e: {h: $elm$core$Maybe$Nothing, i: $elm$core$Maybe$Nothing},
						f: '',
						g: '',
						d: 'Break'
					}
					]),
				p: '4:15 PM',
				q: ''
			},
				{
				o: _List_fromArray(
					[
						{
						e: {
							h: $elm$core$Maybe$Just(
								_List_fromArray(
									['Monique Sampson'])),
							i: $elm$core$Maybe$Just(
								_List_fromArray(
									['Frank Chapman', 'Angela Davis', 'Jazmine Salas', 'Jess Sundin', 'Marisol Marquez', 'USPCN rep']))
						},
						f: 'The purpose of this plenary is to educate audience members on the need to contextualize lessons from\npeople’s movements in order to understand how we can continue to successfully fight against white\nsupremacy and capitalism.',
						g: 'Main Hall',
						d: '[PANEL] Lessons from the Struggle'
					}
					]),
				p: '4:30 PM',
				q: 'Plenary'
			},
				{
				o: _List_fromArray(
					[
						{
						e: {h: $elm$core$Maybe$Nothing, i: $elm$core$Maybe$Nothing},
						f: 'Followed by dinner and social gathering.',
						g: '',
						d: 'Recess'
					}
					]),
				p: '6:00 PM',
				q: ''
			}
			])
	},
		{
		a_: 'Sunday, November 24',
		a9: _List_fromArray(
			[
				{
				o: _List_fromArray(
					[
						{
						e: {h: $elm$core$Maybe$Nothing, i: $elm$core$Maybe$Nothing},
						f: '',
						g: '',
						d: 'Continental Breakfast'
					}
					]),
				p: '9:00 AM',
				q: ''
			},
				{
				o: _List_fromArray(
					[
						{
						e: {h: $elm$core$Maybe$Nothing, i: $elm$core$Maybe$Nothing},
						f: 'Resolution to Refound the National Alliance: Election of Leadership, Report Back from Workshops.',
						g: 'Main Hall',
						d: 'Final Session'
					}
					]),
				p: '10:00 AM',
				q: 'Plenary'
			},
				{
				o: _List_fromArray(
					[
						{
						e: {h: $elm$core$Maybe$Nothing, i: $elm$core$Maybe$Nothing},
						f: '',
						g: '',
						d: 'Conference Adjourns'
					}
					]),
				p: '12:00 PM',
				q: 'Conference Adjourns'
			}
			])
	}
	]);
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $elm$html$Html$em = _VirtualDom_node('em');
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$strong = _VirtualDom_node('strong');
var $author$project$Main$agendaSession = function (session) {
	var speakers = function () {
		var _v1 = session.e.i;
		if (!_v1.$) {
			var people = _v1.a;
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$strong,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Speaker(s): ')
								])),
							$elm$html$Html$text(
							$elm$core$String$concat(
								A2($elm$core$List$intersperse, ' ', people)))
						]))
				]);
		} else {
			return _List_Nil;
		}
	}();
	var moderators = function () {
		var _v0 = session.e.h;
		if (!_v0.$) {
			var people = _v0.a;
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$strong,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Moderator(s): ')
								])),
							$elm$html$Html$text(
							$elm$core$String$concat(
								A2($elm$core$List$intersperse, ' ', people)))
						]))
				]);
		} else {
			return _List_Nil;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h3,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(session.d)
						])),
					$elm$html$Html$text(session.f)
				]),
			_Utils_ap(
				speakers,
				_Utils_ap(
					moderators,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$em,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(session.g)
								]))
						])))));
};
var $elm$html$Html$time = _VirtualDom_node('time');
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Main$agendaSlot = function (slot) {
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$time,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(slot.p)
							])),
						A2(
						$elm$html$Html$strong,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(slot.q)
							])),
						A2(
						$elm$html$Html$ul,
						_List_Nil,
						A2($elm$core$List$map, $author$project$Main$agendaSession, slot.o))
					]))
			]));
};
var $author$project$Main$agendaDay = function (daySchedule) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$h3,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(daySchedule.a_)
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			A2($elm$core$List$map, $author$project$Main$agendaSlot, daySchedule.a9))
		]);
};
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $author$project$Main$agendaSection = A2(
	$elm$html$Html$section,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$id('agenda'),
			$elm$html$Html$Attributes$class('dark')
		]),
	_Utils_ap(
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Program')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$strong,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Please take a few minutes to review the workshops, and tell us which sessions you plan to attend. ')
							])),
						$elm$html$Html$text('This will help us assign workshops to the most-appropriate rooms. Please visit '),
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('http://bit.ly/NAARPRworkshops')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('http://bit.ly/NAARPRworkshops')
							])),
						$elm$html$Html$text(' to enter your workshop preferences.')
					]))
			]),
		$elm$core$List$concat(
			A2($elm$core$List$map, $author$project$Main$agendaDay, $author$project$Data$agenda))));
var $elm$html$Html$address = _VirtualDom_node('address');
var $author$project$Data$geoLocation = {
	b4: _List_fromArray(
		['CTU Hall', '1901 W. Carroll Avenue', 'Chicago, IL 60612']),
	cK: 'geo:41.88744,-87.67559?z=19',
	cZ: 'https://www.openstreetmap.org/export/embed.html?bbox=-87.67702996730804%2C41.88651808028945%2C-87.6734894514084%2C41.88836311585891&amp;layer=mapnik&amp;marker=41.88744060473486%2C-87.67525970935822',
	c_: 'https://www.openstreetmap.org/way/209829229'
};
var $elm$html$Html$iframe = _VirtualDom_node('iframe');
var $elm$html$Html$br = _VirtualDom_node('br');
var $author$project$Main$streetAddress = A2(
	$elm$core$List$intersperse,
	A2($elm$html$Html$br, _List_Nil, _List_Nil),
	A2($elm$core$List$map, $elm$html$Html$text, $author$project$Data$geoLocation.b4));
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Main$attendSection = A2(
	$elm$html$Html$section,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$id('attend'),
			$elm$html$Html$Attributes$class('dark')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Attend')
				])),
			A2(
			$elm$html$Html$h3,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('The conference has concluded.')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Stay tuned for updates!')
				])),
			A2(
			$elm$html$Html$h3,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Location & Directions')
				])),
			A2(
			$elm$html$Html$address,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href($author$project$Data$geoLocation.cK)
						]),
					$author$project$Main$streetAddress)
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$em,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('This is an accessible space.')
						]))
				])),
			A2(
			$elm$html$Html$iframe,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src($author$project$Data$geoLocation.cZ)
				]),
			_List_Nil),
			A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$href($author$project$Data$geoLocation.c_)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('View larger map.')
				])),
			A2(
			$elm$html$Html$h3,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Public Transit')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('The CTU hall is on N. Damen Ave. The #50 CTA bus runs on Damen: '),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('https://www.transitchicago.com/bus/50/')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('https://www.transitchicago.com/bus/50/')
						]))
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Grand Ave. is the nearest major east west street, three blocks north of Carroll Ave. The bus line there is #65: '),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('https://www.transitchicago.com/bus/65/')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('https://www.transitchicago.com/bus/65/')
						]))
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('If you come from O\'Hare on the Blue Line ('),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('https://www.transitchicago.com/blueline/')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('https://www.transitchicago.com/blueline/')
						])),
					$elm$html$Html$text(') you would transfer to the #50 bus at Damen stop'),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('https://www.transitchicago.com/station/damo/')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('https://www.transitchicago.com/station/damo/')
						]))
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('If you come from Midway on the Orange Line ('),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('https://www.transitchicago.com/orangeline/')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('https://www.transitchicago.com/orangeline/')
						])),
					$elm$html$Html$text(') the fastest way is to transfer to the #50 bus at 13th and Archer: '),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('https://www.transitchicago.com/station/35or/')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('https://www.transitchicago.com/station/35or/')
						]))
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('If you come from the Holiday Inn on West Harrison on the Blue Line, you would transfer to the #50 bus at the Illinois Medical District stop: '),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('https://www.transitchicago.com/station/ilmd/')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('https://www.transitchicago.com/station/ilmd/')
						]))
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('If you come from the Inn of Chicago, take the #65 bus on Grand: '),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('https://www.transitchicago.com/bus/65/')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('https://www.transitchicago.com/bus/65/')
						]))
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('If you come from the Crowne Plaza, take the #20 bus ('),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('https://www.transitchicago.com/bus/20/')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('https://www.transitchicago.com/bus/20/')
						])),
					$elm$html$Html$text(') to Damen and then transfer to the #50 bus.')
				])),
			A2(
			$elm$html$Html$h3,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Parking')
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Conference parking is available in the lots on the East, West and South side of the building (see map). There is also street parking available along Carroll Avenue.')
				])),
			A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src('/images/parking.png'),
					A2($elm$html$Html$Attributes$style, 'max-width', '100%')
				]),
			_List_Nil)
		]));
var $elm$html$Html$button = _VirtualDom_node('button');
var $author$project$Data$endorsements = _List_fromArray(
	[
		{a: '/images/orgs/aaan.jpg', b: 'Arab American Action Network (AAAN)'},
		{a: '/images/orgs/blmc.png', b: 'Black Lives Matter (BLM) Chicago'},
		{a: '/images/orgs/codepink.png', b: 'CODEPINK'},
		{a: '/images/orgs/hrint.png', b: 'Human Rights Initiative (HRI) North Texas'},
		{a: '/images/orgs/ctu.png', b: 'Chicago Teachers Union (CTU)'},
		{a: '/images/orgs/ctjc.png', b: 'Chicago Torture Justice Center'},
		{a: '/images/orgs/ncbl.png', b: 'National Conference Black Lawyers (NCBL)'},
		{a: '/images/orgs/naacpsl.png', b: 'NAACP St. Louis, MO'},
		{a: '/images/orgs/aptp.png', b: 'Anti Police-Terror Project'},
		{a: '/images/orgs/psysr.png', b: 'Psychologists for Social Responsibility'},
		{a: '/images/orgs/nafcon.png', b: 'National Alliance for Filipino Concerns (NAFCON)'},
		{a: '/images/orgs/obs.jpg', b: 'Organization of Black Students (OBS) University of Chicago'},
		{a: '/images/orgs/paca.png', b: 'Pan-African Community Action (PACA)'},
		{a: '/images/orgs/assatasdaughters.png', b: 'Assata\'s Daughters'},
		{a: '/images/orgs/iwa.png', b: 'International Women\'s Alliance'},
		{a: '/images/orgs/abusa.png', b: 'Anakbayan USA'},
		{a: '/images/orgs/kaarpr.jpg', b: 'Kentucky Alliance Against Racist & Political Repression (KAARPR)'},
		{a: '/images/orgs/soul.png', b: 'Southsiders Organized for Unity and Liberation'},
		{a: '/images/orgs/uspcn.jpg', b: 'U.S. Palestinian Community Network (USPCN)'},
		{a: '/images/orgs/ftp.jpg', b: 'Forced Trajectory Project'},
		{a: '/images/orgs/powir.jpg', b: 'People\'s Opposition to War, Imperialism, and Racism (POWIR)'},
		{a: '/images/orgs/noc.jpg', b: 'San Jose Nihonmachi Outreach Committee (NOC)'},
		{a: '/images/orgs/crfhd.jpg', b: 'Campaign for Respect, Fairness and Human Dignity (CRF Human Dignity)'},
		{a: '/images/orgs/sds.png', b: 'National Students for a Democratic Society (SDS)'},
		{a: '/images/orgs/uapb.png', b: 'Utah Against Police Brutality'},
		{a: '/images/orgs/l4a.png', b: 'Legalization for All Network (L4A)'},
		{a: '/images/orgs/cbr.jpg', b: 'Chicago Boricua Resistance'},
		{a: '/images/orgs/caarpr.png', b: 'Chicago Alliance Against Racist & Political Repression (CAARPR)'},
		{a: '/images/orgs/cfcf.png', b: 'Coalition for Civil Freedoms'},
		{a: '/images/orgs/migrante.png', b: 'Migrante'},
		{a: '/images/orgs/ofuapb.jpg', b: 'Ohio Families Unite Against Police Brutality'},
		{a: '/images/orgs/tcc4j.jpg', b: 'Twin Cities Coalition for Justice 4 Jamar (TCCJ4J)'},
		{a: '/images/orgs/ccf.jpg', b: 'Common Connections Foundation'},
		{a: '/images/orgs/cchrp.png', b: 'Chicago Committee for Human Rights in the Philippines (CCHRP)'},
		{a: '/images/orgs/gusa.png', b: 'GABRIELA-USA'},
		{a: '/images/orgs/frso.png', b: 'Freedom Road Socialist Organization (FRSO)'},
		{a: '/images/orgs/uwf.webp', b: 'United Working Families'},
		{a: '/images/orgs/solf.png', b: 'Save One Life Foundation'},
		{a: '/images/orgs/vfp.png', b: 'Veterans for Peace'},
		{a: '/images/orgs/iac.jpg', b: 'International Action Center (IAC)'},
		{a: '/images/orgs/eaa.png', b: 'Eastside Arts Alliance'},
		{a: '/images/orgs/wamm.gif', b: 'Women Against Military Madness'},
		{a: '/images/orgs/ntac.png', b: 'North Texas Action Committee (NTAC)'},
		{a: '/images/orgs/swk.webp', b: 'Stand With Kashmir'},
		{a: '/images/orgs/mirac.jpg', b: 'Minnesota Immigrant Rights Action Committee (MIRAC)'},
		{a: '/images/orgs/uspc.png', b: 'U.S. Peace Council'},
		{a: '/images/orgs/mxgm.jpg', b: 'Malcolm X Grassroots Movement (MXGM)'},
		{a: '/images/orgs/fu4j.jpg', b: 'Families United 4 Justice'},
		{a: '/images/orgs/njm.jpg', b: 'National Jericho Movement'},
		{a: '/images/orgs/tcac.png', b: 'Tallahassee Community Action Committee'},
		{a: '/images/orgs/gkmc.jpg', b: 'Good Kids Mad City'},
		{a: '/images/orgs/nlm.jpg', b: 'Native Lives Matter'},
		{a: '/images/orgs/ccso.jpg', b: 'Centro CSO'},
		{a: '/images/orgs/jcac.jpg', b: 'Jacksonville Community Action Committee (JCAC)'},
		{a: '/images/orgs/pilsenalliance.jpg', b: 'Pilsen Alliance'},
		{a: '/images/orgs/wapb.jpg', b: 'Women’s All Point Bulletin'}
	]);
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $author$project$Main$endorsementsLink = A3(
	$elm$html$Html$node,
	'big',
	_List_Nil,
	_List_fromArray(
		[
			A2(
			$elm$html$Html$em,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Full list of endorsements: '),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href($author$project$Data$siteInfo.cN)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text($author$project$Data$siteInfo.cN)
						]))
				]))
		]));
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$Main$sponsorItem = function (sponsor) {
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(sponsor.a),
						$elm$html$Html$Attributes$alt(sponsor.b),
						$elm$html$Html$Attributes$title(sponsor.b)
					]),
				_List_Nil)
			]));
};
var $author$project$Main$endorsementsSection = A2(
	$elm$html$Html$section,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$id('endorsements')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Endorsements')
				])),
			A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$href('https://forms.gle/yDW8S11LEiGBKMHR9')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Endorse the Call')
						]))
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[$author$project$Main$endorsementsLink])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			A2($elm$core$List$map, $author$project$Main$sponsorItem, $author$project$Data$endorsements)),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[$author$project$Main$endorsementsLink])),
			A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$href('https://forms.gle/yDW8S11LEiGBKMHR9')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Endorse the Call')
						]))
				]))
		]));
var $elm$html$Html$footer = _VirtualDom_node('footer');
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $author$project$Main$navbar = A2(
	$elm$html$Html$nav,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('dark')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('#about')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('About')
								]))
						])),
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('#endorsements')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Endorsements')
								]))
						])),
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('#agenda')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Program')
								]))
						])),
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('#logistics')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('FAQ')
								]))
						])),
					A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('#attend')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Attend')
								]))
						]))
				]))
		]));
var $author$project$Main$footerSection = A2(
	$elm$html$Html$footer,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$id('end'),
			$elm$html$Html$Attributes$class('dark')
		]),
	_List_fromArray(
		[$author$project$Main$navbar]));
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$header = _VirtualDom_node('header');
var $author$project$Main$heroButtons = A2(
	$elm$html$Html$ul,
	_List_Nil,
	_List_fromArray(
		[
			A2(
			$elm$html$Html$li,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#attend')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Attend')
								]))
						]))
				])),
			A2(
			$elm$html$Html$li,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#about')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Learn')
								]))
						]))
				])),
			A2(
			$elm$html$Html$li,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#speakers')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Speakers')
								]))
						]))
				])),
			A2(
			$elm$html$Html$li,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#endorsements')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Endorsements')
								]))
						]))
				]))
		]));
var $author$project$Main$hero = A2(
	$elm$html$Html$header,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$id('top'),
			$elm$html$Html$Attributes$class('dark')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$h1,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text($author$project$Data$siteInfo.d)
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			A3($author$project$Main$insertLink, 'National Alliance', $author$project$Data$siteInfo.cN, $author$project$Data$siteInfo.ds)),
			$author$project$Main$heroButtons
		]));
var $author$project$Main$logistics = _List_fromArray(
	[
		{
		B: _List_fromArray(
			[
				$elm$html$Html$text('To see nearby discounted hotel rates or request housing visit: '),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('https://naarpr.org/call-to-refound/housing/')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('https://naarpr.org/call-to-refound/housing/')
					]))
			]),
		D: 'What hotels and housing are available?'
	},
		{
		B: _List_fromArray(
			[
				$elm$html$Html$text('Organizations can send members to the conference or just '),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('https://forms.gle/yDW8S11LEiGBKMHR9')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('endorse the call to refound the alliance')
					])),
				$elm$html$Html$text('to show support.')
			]),
		D: 'How can other organizations support the alliance?'
	},
		{
		B: _List_fromArray(
			[
				$elm$html$Html$text('You can share our posts on social media ('),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('https://fb.me/naarpr')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('facebook')
					])),
				$elm$html$Html$text(', '),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('https://twitter.com/naarpr')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('twitter')
					])),
				$elm$html$Html$text(', & '),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('https://instagram.com/NationalAARPR')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('instagram')
					])),
				$elm$html$Html$text(') and even '),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('https://naarpr.org/product/donate/')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('donate to support the alliance')
					])),
				$elm$html$Html$text('. Many activist groups across the country are also holding fundraisers to send delegates to the alliance. '),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('https://www.facebook.com/NAARPR/posts/535744867248555')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Support and contribute to them too')
					])),
				$elm$html$Html$text(' if you are able. ')
			]),
		D: 'I can\'t attend the conference, how else can I help?'
	},
		{
		B: _List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Yes! See the '),
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('#attend')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('\"Attend\" section')
							])),
						$elm$html$Html$text(' for details.')
					]))
			]),
		D: 'Is there parking and/or public transportation?'
	},
		{
		B: _List_fromArray(
			[
				$elm$html$Html$text('The Chicago Teachers Union Hall is an accessible building.')
			]),
		D: 'Is the conference venue accessible?'
	},
		{
		B: _List_fromArray(
			[
				$elm$html$Html$text('We will add recommendations soon. Please check back later.')
			]),
		D: 'What food will be available near the conference?'
	},
		{
		B: _List_fromArray(
			[
				$elm$html$Html$text('We will add childcare information soon. Please check back later.')
			]),
		D: 'Will there be childcare available during the conference?'
	},
		{
		B: _List_fromArray(
			[
				$elm$html$Html$text('Tabling requests can be submitted at: '),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('https://naarpr.org/call-to-refound/conference-tabling/')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('https://naarpr.org/call-to-refound/conference-tabling/')
					]))
			]),
		D: 'Can we set up a table at the conference?'
	},
		{
		B: _List_fromArray(
			[
				$elm$html$Html$text('The online pre-registration deadline is Wednesday, November 20th. All other registrations will have to be done in-person at the event.')
			]),
		D: 'Is there a deadline to register for the conference?'
	},
		{
		B: _List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('For press and media inquiries, please visit: ')
					])),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('https://naarpr.org/contact/media/')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('https://naarpr.org/contact/media/')
					]))
			]),
		D: 'I\'m a reporter or writer who\'s interested in coming. Where can I get more information?'
	}
	]);
var $author$project$Main$logisticsSection = A2(
	$elm$html$Html$section,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$id('logistics')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Logistics (FAQ)')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			A2(
				$elm$core$List$map,
				function (item) {
					return A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$strong,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(item.D)
									])),
								A2($elm$html$Html$p, _List_Nil, item.B)
							]));
				},
				$author$project$Main$logistics))
		]));
var $elm$html$Html$main_ = _VirtualDom_node('main');
var $author$project$Data$quoteText = '\"It is essential to resist the depiction of history as the work of heroic individuals in order for people today to recognize their potential agency as a part of an ever-expanding community of struggle.\" —Angela Y. Davis';
var $author$project$Main$quoteSection = A2(
	$elm$html$Html$section,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$id('quote'),
			$elm$html$Html$Attributes$class('dark')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text($author$project$Data$quoteText)
				]))
		]));
var $elm$html$Html$figcaption = _VirtualDom_node('figcaption');
var $elm$html$Html$figure = _VirtualDom_node('figure');
var $author$project$Main$speakerItem = function (speaker) {
	var picture = function () {
		var _v0 = speaker.a;
		if (_v0.$ === 1) {
			return _List_Nil;
		} else {
			var imgUrl = _v0.a;
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(imgUrl),
							$elm$html$Html$Attributes$alt(speaker.b),
							$elm$html$Html$Attributes$title(speaker.b)
						]),
					_List_Nil)
				]);
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$figure,
				_List_Nil,
				_Utils_ap(
					picture,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$figcaption,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h3,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(speaker.b)
										])),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(speaker.c)
										]))
								]))
						])))
			]));
};
var $author$project$Data$speakers = _List_fromArray(
	[
		{
		c: 'Angela Davis is an American political activist, academic scholar, and author from Birmingham, Alabama.\nShe emerged as a prominent counterculture activist during the late 1960s while working with the U.S.\nCommunist Party and the Black Panther Party. In August 1970 at a Marin County California court room, Jonathan\nJackson and others took Judge Harold J. Haley, a district attorney, and several jury members hostage in an\nattempt to publicize prison conditions and state abuses against the Soledad Brothers. This situation resulted in\nthe deaths of Judge Haley, Jonathan Jackson, James McClain, and William Christmas. Despite Davis not being in\nthe area at the time, the police named her an accomplice to the crime. 2 months later, she was captured and\nimprisoned. Davis was acquitted of all charges on June 4, 1972. Upon release, she called for a national alliance to\nfree other political prisoners. This is what lead to the National Alliance Against Racist &amp; Political Repression\n(NAARPR). In the 40+ years since her release, Davis has been a prolific writer on the themes of women’s rights\nand leadership, prisoners’ rights, liberation politics, anti-racism, gender equity, and cultural studies. Some of\nDavis’s published work includes: Women, Race &amp; Class, Are Prisons Obsolete? and Freedom is a Constant\nStruggle: Ferguson, Palestine and the Foundations of a Movement.',
		a: $elm$core$Maybe$Just('/images/people/angeladavis.jpg'),
		b: 'Angela Y. Davis, PhD'
	},
		{
		c: 'Frank Chapman was wrongfully convicted of murder and armed robbery in 1961 and sentenced to life and 50\nyears in the Missouri State Prison. In 1973, the National Alliance Against Racist and Political Repression (NAARPR)\ntook up Frank’s case. In 1976 he was released after being incarcerated for 15 years. In 1983, he was elected\nExecutive Director of NAARPR. He worked alongside Charlene Mitchell, who preceded him as Executive Director\nof NAARPR, and with Angela Davis on building an international campaign to free Rev. Ben Chavis and the\nWilmington Ten, Joann Little, Geronimo Pratt, Leonard Peltier and others falsely accused and politically\npersecuted. Currently, Chapman is the Field Organizer and Educational Director of the Chicago Alliance Against\nRacist &amp; Political Repression (CAARPR). He believes in the inalienable democratic right of Black people to\ndetermine who polices their communities and how their communities are policed. Chapman recently published\nhis autobiography The Damned Don’t Cry: Pages from the Life of a Black Prisoner &amp; Organizer.',
		a: $elm$core$Maybe$Just('/images/people/frankchapman.jpg'),
		b: 'Frank Chapman'
	},
		{
		c: 'Ariel Atkins is the lead organizer of Black Lives Matter Chicago. She has been a member of BLMChi for three\nyears and a member of the alliance for four. She was heavily involved in the #Justice4Laquan campaign, is\ncurrently involved in the Justice for Ronnieman campaign, the campaign for CPAC, and many others throughout\nChicago. She believes that nothing can be won without educating and mobilizing the people for our collective\nwin. She\'s a growing abolitionist, likes coconut oil in her hair and white fragility tears in her coffee.',
		a: $elm$core$Maybe$Just('/images/people/arielatkins.jpg'),
		b: 'Ariel Atkins'
	},
		{
		c: 'I have been a warrior in the struggle for community control of education every since the NYC school\nstrike in 1967. .I was a social worker and an educator. I helped form The New Abolitionist Movement because as\neveryone knows, &quot;Cats don&#39;t educate mice, they eat them.&quot; This organization grew out of the movement to\nsupport the Ocean Hill Brownsville Community Control struggle and the rest is history. I am dedicated to the\ninstitutions of the public school system because to paraphrase Frederick Douglass, ‘education and slavery are\nincompatible’. As a member of the Lynne Stewart Org., I was proud to serve as financial administrator and\nfundraiser.\nThe entire movement of the sixties was based on the belief that our children are the future and it is the duty of\neach generation to guarantee the future of the ones who come after them.\nI joined the Green Party decades ago after college but only became active when Cynthia McKinney ran for the\npresidency. I may still be addicted to potato chips and chocolate but I am not addicted to a 2 party mythology or\nthe dixiecrat Democrats.',
		a: $elm$core$Maybe$Just('/images/people/bettydavis.jpg'),
		b: 'Betty Davis'
	},
		{
		c: 'Bernadette Ellorin serves as the Vice-Chairperson for North America for the International League of Peoples\nStruggles or ILPS, an anti-imperialist and democratic formation representing national and social liberations\nmovements present in over 45 countries and autonomous states and in 6 continents. Ellorin is also the national\nspokesperson and former chairperson of BAYAN USA, an alliance of over 20 Filipino grassroots organizations in\nthe US representing workers, women, youth and students. It is the oldest and largest overseas chapter of BAYAN\nPhilippines, the main political center of the national democratic movement with a socialist perspective in the\nPhilippines.',
		a: $elm$core$Maybe$Just('/images/people/bernadetteellorin.jpg'),
		b: 'Bernadette Ellorin'
	},
		{
		c: 'Toshira Garraway is the fiance of Justin Tiegen, and mother to his young son. Justin was killed in 2009, when by\nSt. Paul police who beat him to death and dumped his body in the garbage dumpster.',
		a: $elm$core$Maybe$Just('/images/people/toshiragarraway.jpg'),
		b: 'Toshira Garraway'
	},
		{
		c: 'Cherrene Horazuk has been an organizer for over 30 years. She is the president of AFSCME 3800 and the\nsecretary of AFSCME Council 5 in Minnesota. In her 15 years of union activism, she has led a strike, and helped\nwin a $15 minimum wage, paid parental leave, and workplace protections for trans workers. Prior to union\norganizing, Cherrene was the director of CISPES and led campaigns against US intervention and in support of the\npeople’s movements. She helped lead a 100,000 person antiwar rally during the second gulf war, was on the\nnational steering committee for Stonewall 25, participated in an ACT-UP protest that shut down every bridge and\ntunnel in Manhattan during rush hour, and participated in a 100+ person civil disobedience at the Liberty Bell to\ndemand freedom for Mumia Abu Jamal.',
		a: $elm$core$Maybe$Just('/images/people/cherrenehorazuk.jpg'),
		b: 'Cherrrene Horazuk'
	},
		{
		c: 'Bassem Kawar is a Chicago-based Jordanian American who graduated with a Bachelor of Arts in Sociology from\nDePaul University, where he was active with Students for Justice in Palestine (SJP). He began his career as a\ncommunity organizer with the Arab American Action Network (AAAN), working predominantly in the Arab\nAmerican and Arab immigrant communities of Chicago and its southwest suburbs. Over the past few years,\nKawar worked on Jesus “Chuy” Garcia’s historic Chicago mayoral campaign, organized with the teachers in\nDetroit on their rank-and-file contract battle with the city, and led the National Campaign to TAKE ON HATE. He\nis a leader in Chicago of the U.S. Palestinian Community Network (USPCN), passionate about supporting the\nuplifting of oppressed communities, and believes that grassroots organizing and strategic alliances are the best\nways to achieve that.',
		a: $elm$core$Maybe$Just('/images/people/bassemkawar.jpg'),
		b: 'Bassem Kawar'
	},
		{
		c: 'Christina Kittle is a rank and file educator and community organizer from Jacksonville, Florida. She works with\nthe Jacksonville Community Action Committee and Coalition for Consent. After being a political target of the\npolice, which led to a peaceful protest turning into a police brutality case where her and four other activists were\nput on trial and won their freedom, she now works with her organizations to fight for police accountability. The\ndemand is for a police accountability council wherein which community members are democratically elected on\na review board with the power to fire corrupt police found guilty of crimes against the people. She has also been\nactive in organizing for justice for Black transgender women killed in Jacksonville when the police refused to\nadhere to the federal practices of Matthew Shepard and James Byrd Jr law, which dictates that police should use\nperceived gender identity during the investigation of hate crimes. She continues to push for police accountability\nand gender liberation in the Deep South.',
		a: $elm$core$Maybe$Just('/images/people/christinakittle.jpg'),
		b: 'Christina Kittle'
	},
		{
		c: 'Sydney Lovіng is co-chair of the North Texas Action Committee, a Dallas-based organization dedicated to fighting\npolice brutality, prison profiteering, racist political repression, and economic injustice. She has been an activist\nsince she was a student organizing with Decolonize Media Collective, which led the campaign to force the\nuniversity&#39;s divestment from the private prison industry, and organized with BLM 413 and Out Now in\nSpringfield, MA (2013-2017). She has worked as a kindergarten teacher for the past year and a half.',
		a: $elm$core$Maybe$Just('/images/people/sydneylovіng.jpg'),
		b: 'Sydney Lovіng'
	},
		{
		c: 'Pastor Emma Lozano has been an activist for the immigrant/undocumented community for decades. She was\nborn in Texas and raised in the Pilsen neighborhood of Chicago. As a teenager and young adult, she worked\nalongside her brother Rudy Lozano, an activist for workers and the Latino community who was assassinated in\n1983. Since his death, Lozano has dedicated her life to the continuation of her brother’s work.\nShe is the founder and President of Centro Sin Fronteras where she has worked to stop thousands of\ndeportations. In the 1990s and early 2000s she was active in the struggle for undocumented parents to get\ncontrol of their children’s schools through local school councils, has fought against gentrification, and was a part\nof organizing the largest immigrant rights marches in 2006.\nShe is a co-founder of Familia Latina Unida along with Elvira Arellano who she was pastor to when she stayed in\nsanctuary in 2006 at Adalberto UMC. She is currently the pastor of sanctuary church Lincoln UMC where she\ncontinues to counsel those who are in deportation proceedings and works with the local youth to close the 20\nyear life expectancy gap that exists in Chicago.\nShe is a two time breast cancer survivor and works with the Youth Health Service Corps to provide free\nmammograms to the undocumented and uninsured communities in Chicago through RUSH University. This\nprogram runs in over 20 CPS grammar and high schools and works with dozens of health clinics and hospitals in\nthe city of Chicago. Lincoln UMC is a sanctuary to the undocumented and LGBTQ communities.\nPastor Lozano was recently appointed as the Chaplain to the National President of LULAC and has been President\nof Council 5294 since 2014. She is also the Chair for Womens Issues at the LULAC Illinois level.',
		a: $elm$core$Maybe$Just('/images/people/emmalozano.png'),
		b: 'Pastor Emma Lozano'
	},
		{
		c: 'Miriam Magaña organizes with the Minnesota Immigrant Rights Action Committee (MIRAC)',
		a: $elm$core$Maybe$Just('/images/people/miriammagaña.jpg'),
		b: 'Miriam Magaña'
	},
		{
		c: 'Kent Marrero is a Queer, Puerto Rican community advocate who has worked alongside grassroots liberation\nmovements across the Panhandle, North Florida, and Central Florida for over a decade. They currently serve as\nthe Jacksonville Advocacy Coordinator for QLatinx and they are an Equality Florida&#39;s Jacksonville TransAction\nCouncil Member. Previously, Kent provided LGBTQ+ representation serving as a staff writer for the Deep South’s\nlargest LGBTQ magazine covering stories about hate crime cases and interviewing comedians like Devin Green.\nKent sprang to action after the Pulse nightclub attack. First hand experience navigating reporters at The LGBT\nCenter of Orlando reinforced that framing is essential to keeping control of community narratives. The media\nreported Pulse as a terrorist attack until Orlando LGBTQ+ advocates pushed to reclaim the narrative as an\nLGBTQ+ hate crime. Six months after Pulse, Story Core hosted a Taking Your Pulse forum where the community\nbegan to pose questions surrounding racial erasure within the public Pulse narrative. This forum is also where\nKent called on the media to intentionally reflect on how they can cover stories with more respect and empathy\nto the family, survivors, and affected community in the audience.',
		a: $elm$core$Maybe$Just('/images/people/kentmarrero.jpg'),
		b: 'Kent Marrero'
	},
		{
		c: 'CeCe McDonald a Minneapolis-based artist and activist. As a Black transgender woman, she is a survivor of\ntransphobic violence, criminalization, and incarceration. During her time in prison, her writing inspired an\ninternational community of activists to support the #FreeCeCe campaign and to advance the broader\nmovements for transgender liberation and prison abolition. Since her release in 2014, she has become a\nfrequent speaker and commentator on these issues. She has worked with numerous organizations, including the\nTransgender Youth Support Network in Minneapolis and the Gender Justice League in Seattle; and was selected\nas a 2018 Activist-in-Residence at the Barnard Center for Research on Women and one of this year’s Soros\nJustice fellows.',
		a: $elm$core$Maybe$Just('/images/people/cecemcdonald.jpg'),
		b: 'CeCe McDonald'
	},
		{
		c: 'Tracy Molm is a member of the Minnesota Anti War Committee and Freedom Road Socialist Organization. In\n2010 her apartment was one seven homes raided by the FBI as part of an investigation of solidarity and anti war\nactivists. Tracy was also one of the 23 activists that refused to testify to a grand jury as part of this same\ninvestigation.',
		a: $elm$core$Maybe$Just('/images/people/tracymolm.jpg'),
		b: 'Tracy Molm'
	},
		{
		c: 'Carlos Montes is a nationally recognized immigrant rights, antiwar, pro public education Chicano activist and\nformer political prisoner. His family immigrated from Mexico and he grew up in Los Angeles. Montes was part of\nChicano civil rights movement and co-founder of the Brown Berets, active in MEChA and first Chicano\nMoratorium against Vietnam war 1969. He participated in the Poor Peoples Campaign in 1968, Latinos for\nJackson in 1984, and the march on the RNC in 2008 and 2012.\nMontes was arrested many times for his organizing. Also indicted twice by a grand jury, 1968 he was part of the\nEast LA 13 who were blamed for the High School Walkouts, as part of the Biltmore 6 for their protest on Gov.\nReagan, and most recently in the aftermath of the 2010 Grand Jury subpoenas and raids on antiwar and\ninternational solidarity activists. Due to COINTELPRO the many arrests and threats to life he was forced to go on\nthe run in the early 1970s. He lived in Mexico and later in El Paso, Tejas where he organized support for the\nFarah textile strike and the La Raza Unida Party.\nCurrently retired and a community organizer with Centro CSO in Boyle Heights neighborhood of Los Angeles, and\na writer for Fight Back! News. In Boyle Heights he has also been an advocate for the environment working to\nclose Exide battery cycling plant. Has been a longtime advocate for public education and organized for new high\nschools and to oppose charter takeovers of public schools.',
		a: $elm$core$Maybe$Just('/images/people/carlosmontes.jpg'),
		b: 'Carlos Montes'
	},
		{
		c: 'Sheridan Murphy is President of the Board of the International Leonard Peltier Defense Committee and served\nas the State Executive Director of the American Indian Movement of Florida from 1987-2006. Sheridan has been\nactive in Indian rights since joining the Tiospaye Indian Student Council at the University of South Dakota in 1983.\nWith a group of concerned Indian and non-Indian students he helped found the Tiyospaye Indian Student\nOrganization at the University of South Florida in 1985. With David Goyette he first founded the Florida Indian\nAlliance in 1985 and then the Florida AIM chapter in1987. He served as executive director of Florida AIM from its\nfounding in 1987 until 2007. He joined the board of the International Leonard Peltier Defense Committee in 2017\nand became the President of the Board in 2018.\nSheridan first came to action in Florida in 1986 when he lead the effort to close the display of five Timucuan\npeoples remains at the Crystal River State Archaeological site . He was active in virtually every Florida AIM effort\nover twenty years ranging from the 1989-93 campaign to change the name of the Hernando De Soto festival to\nefforts to force FSU to remove its stereotypical mascot to the successful 1995 campaign to defend the\nIndependent Traditional Seminole Nation from relocation.\nSheridan has been active in community efforts being deeply involved in the multicultural CommUNITY campaign\nin the Tampa Bay area serving in the 1990s on the Pinellas County School Board&#39;s Multicultural advisory board.\nHe has been recognized for his service by Brevard Community College, Leonard Peltier Defense Committee, Bring\nPeltier Home Campaign, and the National Peoples Democratic Uhuru Movement among others. He was\nnominated as a delegate to the II and III Continental Encounters in Guatemala City, Guatemala and Esteli,\nNicaragua respectively and was a 1995 nominee for the ACLU Medal of Liberty.',
		a: $elm$core$Maybe$Just('/images/people/sheridanmurphy.jpg'),
		b: 'Sheridan Murphy'
	},
		{
		c: 'Ralph Poynter was born in Western Pennsylvania, the son of a union organizer when Pittsburgh and surrounding\narea was the steel capital of the world. I remained true to the principles of unionism as founding member of the\nTeacher’s Freedom Party Caucus of the UFT. I continued supporting true union principles when the UFT joined\nmanagement by supporting the racist Central Board of Education in NYC against the community control of\nschools movement.\nAfter being released from jail, caused by my forcibly installing the first Black and Latino principals into the NYC\nschool system, I continued the struggle for justice as a Private Investigator who confronted injustice directly. I\nsuccessfully supported Lynne Stewart in her lifelong struggle against the double standard so often practiced in\nthe U.S. injustice system pasts and present by launching a victorious opposition to her incarceration and bringing\nher home.',
		a: $elm$core$Maybe$Just('/images/people/ralphpoynter.png'),
		b: 'Ralph Poynter'
	},
		{
		c: 'Ashley Quiñones is the wife of Brian Quinones, who was murdered by 5 officers in Richfield, Minnesota in\nSeptember 2019. Brian was a father and a hip hop artist named Blessed The MC.',
		a: $elm$core$Maybe$Just('/images/people/ashleyquiñones.jpg'),
		b: 'Ashley Quiñones'
	},
		{
		c: 'Regina Russell: My son Tamon Russell was Wrongly Convicted in 2001 and was given a 45-year sentence with no\nphysical evidence and two unreliable witnesses, even though he was at home with his younger sister and myself\nat the time of the alleged crime. He was brutalized and denied the right to see an attorney. The senior officer\nwho signed the charges against Tamon, Raymond Madigan, was one of the Jon Burge gang of cops. I have always\nbeen a fighter as a union steward in SEIU Local 73 and an Officer, and it was my Union brother and friend, Joe\nIosbaker, who got me involved in the Chicago Alliance and its Campaign To Free Incarcerated Survivors Of Police\nTorture (CFIST). Now together we fight we fight for justice for victims of torture and the wrongly convicted.',
		a: $elm$core$Maybe$Just('/images/people/reginarussell.jpg'),
		b: 'Regina Russell'
	},
		{
		c: 'Michael Sampson started as an activist on the campus of Florida State University where he co-founded multiple\norganizations including Progress Coalition and the Dream Defenders. He has organized on issues regarding\neducation rights as well as combating police brutality and building a national movement for community control\nof the police.\nWith the Dream Defenders, he was a part of the movement to demand justice for Trayvon Martin and ending the\nschool to prison pipeline in Florida. He covered the Ferguson rebellions following the murder of Mike Brown as\nwell as the Baltimore rebellion following the murder of Freddie Gray by police for Fight Back News.\nMichael has organized in Tallahassee and DC, and now organizes in his hometown of Jacksonville, Florida where\nhe helped start the Jacksonville Community Action Committee( JCAC) in 2017 where he has organized for\ncommunity control of the police.',
		a: $elm$core$Maybe$Just('/images/people/michaelsampson.jpg'),
		b: 'Michael Sampson'
	},
		{
		c: 'Nazek Sankari has been a member of the US Palestinian Community Network (USPCN) for two years where she\nserves on the national membership committee for the Chicago chapter. She is currently pursuing a master&#39;s\ndegree in Social Work with an interdepartmental concentration in Gender and Women&#39;s Studies.',
		a: $elm$core$Maybe$Just('/images/people/nazeksankari.jpg'),
		b: 'Nazek Sankari'
	},
		{
		c: 'Jess Sundin was a founding member of the Anti-War Committee in Minneapolis, organizing against US wars in\nIraq and Afghanistan, and military aid to the repressive governments of Colombia and Israel. In 2010, an FBI\ninvestigation into this activism led to an FBI raid on her home (and several others), and she was one of 23 people\nordered to testify before a federal grand jury about their international solidarity work. As a grand jury resister,\nJess helped to build the Committee Stop FBI Repression. In the midst of that work, the November 2015 police\nmurder of Jamar Clark brought forth a powerful movement in Minneapolis to demand #Justice4Jamar. Jess\nhelped found the Twin Cities Coalition for Justice 4 Jamar, where she continues the work with the families of\nJamar and others to fight against police crimes for community control of police.',
		a: $elm$core$Maybe$Just('/images/people/jesssundin.jpg'),
		b: 'Jess Sundin'
	},
		{
		c: 'Masao Suzuki has been active in the Japanese American community in the San Jose, CA for more than 30 years\nwith the Nihonmachi Outreach Committee (NOC). In February of 2017 he gave the keynote speech as the annual\nDay of Remembrance event which commemorates the World War II concentration camps for Japanese\nAmericans. Mr. Suzuki was also a lead organizer for a Japanese American - American Muslim solidarity march and\nrally in San Jose in March of 2017.\nIn 2010 Masao Suzuki was visited by the FBI as part of the raids and Federal Grand Jury subpoenas aimed at\nantiwar and international solidarity activists in the Midwest. He is a frequent writer for Fight Back! newspaper\nand is a member of the Freedom Road Socialist Organization (FRSO). He has taught economics at a community\ncollege for the last 20 years.',
		a: $elm$core$Maybe$Just('/images/people/masaosuzuki.png'),
		b: 'Masao Suzuki'
	},
		{
		c: 'Loretta VanPelt is a founding member and organizer with the Twin Cities Coalition for Justice 4 Jamar in\nMinneapolis, Minnesota.',
		a: $elm$core$Maybe$Just('/images/people/lorettavanpelt.jpg'),
		b: 'Loretta VanPelt'
	},
		{
		c: 'Tanya Watkins is a mother, writer, Black womanist, community organizer and prison abolitionist originally from\nChicago’s South Side. She studied writing and the teaching of writing at Columbia College Chicago with the intent\nof bringing innovative arts programs to schools in low-income communities of color. She is a former South\nSuburban Chicago elected official and served as a Presidential Delegate in 2016. Tanya began as a leader with\nSouthisiders Organized for Unity and Liberation (SOUL) in 2010, a multi-issue, faith-based, social justice\norganization that assists residents to build power in the Chicago Southland. Since being with the organization,\nTanya has led a multitude of campaigns demanding criminal justice reform and police accountability in Chicago;\nmost notably, heading up a series of strategic actions targeting former Cook County State’s Attorney, Anita\nAlvarez in 2015. Currently, Tanya is the Executive Director of SOUL and SOUL in Action.',
		a: $elm$core$Maybe$Just('/images/people/tanyawatkins.jpg'),
		b: 'Tanya Watkins'
	},
		{c: 'Brian L. Ragsdale, is a senior core faculty and skills development coordinator for the School of\nPsychology programs at Walden University. He is an accomplished mixed media artist, self-taught songwriter/pianist, and\nlives on Chicago’s southside. He currently serves as President of the Association of Black Psychologists: Chicago chapter\n(2018-2020) and on the executive board of the Chicago Alliance Against Racist and Political Repression. He earned his MA\n(1998) and PhD (2000) in Clinical Psychology from the University of Rhode Island and is a licensed clinical psychologist in\nIllinois (2001-2018). Their teaching, counseling, and community organizing work is focused on addressing carceral systems\nincluding police brutality, and promoting collective healing responses to anti-Black racism, stress, and trauma. They have\npublished and presented on a range of topics from exploring multidimensional identity concerns, adolescent development,\nLGBTQI, digital learning, social media, and spirituality.', a: $elm$core$Maybe$Nothing, b: 'Brian L. Ragsdale, PhD'},
		{c: 'Devin Branch is a political activist and advocate for self-determination for oppressed nations. He is a former\nmember of the National Steering Committees of the Jericho Movement and is currently a contributor to Re-\nBuild: A New Afrikan Nationalist Periodical.', a: $elm$core$Maybe$Nothing, b: 'Devin Branch'},
		{c: 'Edwin Cortes was a student activist at the University of Illinois at Chicago when he was arrested in the early\n1980s. He became one of 10 men and five women Puerto Rican political prisoners in the U.S. They were targeted\nfor their work advocating for independence for their homeland. They were given decades long sentences, even\nthough no one was injured, nor was there any property damage underlying the charges brought against them by\nthe U.S. government. The movement to obtain their freedom mushroomed into a broad-based human rights\ncampaign stretching from Puerto Rico to Chicago. Pardoned in 1999, Cortes continued to campaign for freedom\nfor Oscar Lopez Rivera, who was finally released in 2017.', a: $elm$core$Maybe$Nothing, b: 'Edwin Cortes'},
		{c: 'Stacy Davis Gates is the Vice President of Chicago Teachers Union (CTU), Local 1. She was an important leader in\nthe path breaking strike that CTU lead in Chicago this fall, and has been a force for changing Chicago politics from\nthe ground up. She has served as the Political and Legislative Director for the Chicago Teachers Union for the\npast six years. While at the CTU, Ms. Davis Gates has been the architect of bold political and legislative\ncampaigns for the schools and city that all Chicagoans deserve. She has raised millions of dollars to elect\nclassroom teachers to all levels of local government and to challenge school privatizers and union-busters, most\nprominently Mayor Rahm Emanuel and his rubber-stamp City Council in 2015. Ms. Davis Gates has also\nspearheaded successful statewide legislative campaigns to pass the strongest charter school accountability\nmeasures in the country, to restore the bargaining rights of Chicago Public Schools employees, and to fully fund\npublic education by ending tax loopholes for the 1%. In 2017, Ms. Davis Gates was elected Chair of United\nWorking Families, an independent political organization by and for working class people and our movements.\nShe also serves as a board member for ACRE, The Action Center on Race &amp; the Economy, a nexus for\norganizations working at the intersection of racial justice and Wall Street accountability. Ms. Davis Gates is\ncurrently on leave from the classroom, where she taught high school social studies for over a decade at\nEnglewood and Clemente High Schools and Mason Community Links High School. She attended Saint Mary’s\nCollege, the University of Notre Dame, and Concordia University. Ms. Davis Gates lives on the south side of\nChicago with her husband and three children.', a: $elm$core$Maybe$Nothing, b: 'Stacy Davis Gates'},
		{c: 'Masai Ehehosi is a citizen of the Republic of New Afrika, and a long time activist in the New Afrikan\nIndependence Movement (NAIM). He is a former Black Liberation Army POW. Masai is a board member of\nCritical Resistance (CR), a grassroots organization that works to build a mass movement to abolish the Prison\nIndustrial Complex (PIC). He currently works with the National Jericho Amnesty Movement for Political Prisoners\nand Prisoners of War, and the Imam Jamil Al-Amin (f/k/a H.Rap Brown) Action Network. Masai is a Muslim, and\npart of Masjid Al-Kabah.', a: $elm$core$Maybe$Nothing, b: 'Masai Ehehosi'},
		{c: 'My name is Bertha Escamilla. I became active in the movement because my son Nick Escamilla was kidnapped\nand arrested by corrupt detectives, Kenneth Boudreau, John Halloran and James O’Brien, who have made\ncareers of locking up innocent Latino and Black men and women. My son Nick was arrested in February 1993 for\na murder he knew nothing about nor had any involvement in. The detectives forced Nick to make a statement by\nbeating, torture and threatening to arrest his pregnant wife and take their 2-year old daughter to DCFS. Nick\nserved 14 1/2 years for murder. Nick served this time and yet is still threatened as a felon. I have been active in\ntrying to get justice for him and so many others. We must not stop. We need to fight to get justice for all. We\nhave to hold corrupt officers, judges and prosecutors accountable for their actions. Laws need to change. We can\ndo this if all fight for justice. We demand freedom for torture victims!', a: $elm$core$Maybe$Nothing, b: 'Bertha Escamilla'},
		{c: 'Jessie Fuentes is an activist in the Humboldt Park Community in Chicago, IL. Fuentes is a graduate of Dr. Pedro\nAlbizu Campos Puerto Rican High School – the Puerto Rican Cultural Center’s very own educational institution.\nFuentes quickly after went to pursue her Bachelor’s Degree in Justice Studies and Latino and Latin American\nStudies (double majored) and has recently earned her Graduate Degree in Community and Teacher Leadership\n(MA). Fuentes has done work around empowering young people in the community, the release of Oscar Lopez\nRivera, anti-gentrification work, education reform, and work around the independence of Puerto Rico. Fuentes\nbegan her political work as the Director of a youth organization in Humboldt Park called the Batey Urbano.\nFuentes is currently the Dean of Students at Dr. Pedro Albizu Campos Puerto Rican High School. Fuentes is also\nthe Co-Chair of the Puerto Rican Agenda in Chicago. The Puerto Rican Agenda is an unincorporated organization\nof local Puerto Rican leaders that seek to influence policy for the advancement of the Puerto Rican community in\nChicago.', a: $elm$core$Maybe$Nothing, b: 'Jessie Fuentes'},
		{c: 'Kimberly Handy-Jones of Waukegan, IL, is the mother of Cordale Q Handy, who was killed by St. Paul police in\nMarch 2017. Ms. Kim regularly travels to Minnesota, to fight for justice for her son and others killed by police in\nMinnesota and beyond. She founded the Cordale Q. Handy In Remembrance of Me Foundation (\nhttps://www.cordaleqhandyinremembranceofme.org/ ), and hosts an annual banquet to honor mothers of\nstolen lives and provide burial cost support.', a: $elm$core$Maybe$Nothing, b: 'Kimberly Handy-Jones'},
		{c: 'Esther Hernandez was nine years old when she moved to Chicago from Puerto Rico with her family. She raised\nthree children in Chicago and has four beloved grandchildren. Twenty-two years ago, Esther’s sons, Juan and\nRosendo Hernandez became victims of Chicago Police Detective Reynaldo Guevara’s coercive police practices. In\n1998, Esther joined Comite Exigimos Justicia in an effort to raise awareness about Guevara and his victims. A\ngroup of resilient women who organized and started a movement to expose corrupt officers Reynaldo Guevara,\nErnest Halvorsen and others who targeted their community. In 2013, Innocent Demand Justice Committee was\nborn. Esther and her son Juan Hernandez decided to start their own committee since the previous group had\nfaded away. Innocent Demand Justice Committee holds monthly meetings, are present at court cases, has\nmotivational speakers at their meetings, holds group and family discussions and collaborates with other\norganizations to improve and strengthen families all in the names of the Wrongfully Convicted.', a: $elm$core$Maybe$Nothing, b: 'Esther Hernandez'},
		{c: 'Dorothy Holmes is the mother of Ronald RonnieMan Johnson who was murdered on October 12th 2014 by the\nChicago Police Department. My fight continues. I will never give up the fight for justice for my son.', a: $elm$core$Maybe$Nothing, b: 'Dorothy Holmes'},
		{c: 'LaTanya Jenifor-Sublett experienced police abuse and torture at Chicago Area 2 police station at the age of 19.\nSentenced to 42 years in the Illinois Department of Corrections at the age of 21 and subsequently spent 21 years\nin prison for a crime that she did not commit. During her incarceration she spent her time studying law, business,\nand social justice. Released from prison October 4, 2013, LaTanya has become an active member of several\nChicago social justice organizations advocating for human rights, social justice, and prison reform and sentencing\nreform. As a member of the Chicago Torture Justice Center Speaker’s Bureau, LaTanya spends time speaking to\nstudents at Chicago Public Schools about the history and trauma of police violence. She is also a member of the\nSurvivor and Family Advisory Council for the Chicago Torture Justice Center and the Harm Reduction “All of Us or\nNone” Advisory board for Men and Women in Prison Ministries (MWIP).', a: $elm$core$Maybe$Nothing, b: 'LeTanya Jenifor-Sublett'},
		{c: 'Marisol Marquez is a working-class Chicana, a proud member of Freedom Road Socialist Organization and has\nbeen fighting against police brutality for years. Marquez grew up as a migrant farmworker and has lived in both\nthe East and West Coast.\nWhen she lived in Florida, a racist vigilante killed 17-year-old Trayvon Martin. Marquez helped lead protests\noutside the courthouse, as the trial took place. She saw how Zimmerman taunted protesters from the\ncourtroom. After Zimmerman was found not guilty of manslaughter, Marquez joined many in calling for national\nprotests.\nNow living in East LA she continues to fight for Chicanos, Latinos, Central Americans. Yearly the organization she\nis also a part of Centro Community Service Organization, puts on a Chicano Moratorium commemoration. These\nevents center around the 1970 moratorium against the Vietnam War. Currently CSO connects the 1970 demands\nwith demands of today which include taking community control over LAPD/LASD.', a: $elm$core$Maybe$Nothing, b: 'Marisol Marquez'},
		{c: 'Melissa McKinnies is an outspoken activist of the Ferguson uprising of 2014 and the mother of Danye said on\nNov.2, 2019, &quot;They lynched my baby.&quot; The police tried to call it suicide but sister Melissa Jones said: &quot;The knots\nin the bed sheets, which did not come from my home, were Navy knots. My son was not in any military, not a\nboy scout.&quot; Her son Danye Jones was hung on October 17, 2018. A year later to the date Javon Jones, 23 year old\nbrother of Danye Jones, was arrested in Chicago and falsely charged, along with his friend Robert Wilkes, with\nuse of a fire arm. Both brother Jones and Wilkes had permits. Melissa Jones and Rayna Turner, the mother of\nRobert Wilkes, came to the Chicago Alliance office seeking help. In the wake of organized protests led by Black\nLive Matter Chicago and the Chicago Alliance Against Racist and Political Repression Javon Jones and his friend\nRobert Wilkes were both released, after charges were dropped, on Nov. 8, 2019.', a: $elm$core$Maybe$Nothing, b: 'Melissa McKinnies'},
		{c: 'Rasmea Odeh was born in Lifta, near Jerusalem, Palestine, in 1947, and was forced from her home a year later\nduring the Nakba (Arabic for &quot;Catastrophe&quot;), which describes the exile of 750,000 Palestinians upon the founding\nof the colonialist state of Israel in 1948.\nUntil 1994, when she immigrated to the U.S., Odeh worked in Jordan, Lebanon, and Palestine at different times\nin refugee resettlement, as a women&#39;s rights organizer, and as an attorney. She was a leading organizer in\nChicagoland’s Palestinian and Arab communities from 2004, when she became the Associate Director of the Arab\nAmerican Action Network (AAAN), until 2017, co-founding the AAAN’s Arab Women’s Committee, which has\nover 800 members and leads the organization’s work fighting against racial profiling and defending immigrant\nrights.\nIn 1969-70, she was tortured and sexually assaulted into a false “confession” by the Israeli military, and served\n10 years in prison, becoming one of the most famous Palestinian political prisoners in the world. For allegedly\nnot disclosing this forty-five year old “conviction” by an Israeli military court, Rasmea was indicted in 2013 by the\nU.S. Department of Justice on an immigration violation, nine years after she had already gained U.S. citizenship.\nAfter three and a half years of valiantly battling for her freedom, and knowing that a Palestinian cannot get a fair\ntrial in U.S. courts, Rasmea accepted a plea agreement, and was forced to permanently leave the States in\nSeptember of 2017. Her cause garnered massive attention and support from a number of the most important\nsocial justice movements in the U.S., including the Black Liberation Movement, as her life story embodies the\nbrave Palestinian people’s struggle for self-determination, the Right of Return, and independence.', a: $elm$core$Maybe$Nothing, b: 'Rasmea Odeh'},
		{c: 'Aislinn Pulley is a co-executive director of the Chicago Torture Justice Center founded out of the historic 2015\nreparations ordinance for survivors of Chicago police torture. Aislinn is also a lead organizer with Black Lives\nMatter Chicago. She was an organizer with We Charge Genocide, a founding member of Insight Arts, a cultural\nnon-profit that used art for social change, and a member of the performance ensemble, End of the Ladder. She is\na founder of the young women’s performance ensemble dedicated to ending sexual assault, Visibility Now, as\nwell as the founder and creator of urban youth magazine, Underground Philosophy.', a: $elm$core$Maybe$Nothing, b: 'Aislinn Pulley'},
		{c: 'Rhonda Ramiro has been an organizer in the movement for National Democracy in the Philippines since the\n1990s. Rhonda was a founder of BAYAN-USA in 2005, establishing the first overseas chapter of the Philippines-\nbased Bagong Alyansang Makabayan (“New Patriotic Alliance” or BAYAN for short), a nationwide, multi-sectoral\nalliance of over a thousand grassroots peoples’ organizations in the Philippines, fighting for national and social\nliberation. Rhonda is currently the Chair of BAYAN-USA, a member of the International Coordinating Committee\nof the International League of Peoples&#39; Struggle, and a member of the Administrative Committee of the United\nNational Anti-War Coalition.', a: $elm$core$Maybe$Nothing, b: 'Rhonda Ramiro'},
		{c: 'Jazmine Salas is a Puerto Rican and Dominican organizer living in Chicago. She grew up in the Puerto Rican\ndiaspora, in Orlando, FL, and became involved in the movement to stop police crimes in 2015 after moving to\nChicago to attend grad school. She is the current co-chair of the Chicago Alliance Against Racist and Political\nRepression and the Chicago Boricua Resistance.', a: $elm$core$Maybe$Nothing, b: 'Jazmine Salas'},
		{c: 'Monique Sampson is an activist with UNF Students for a Democratic Society. In 2017, Monique led the student\nmovement against a self proclaimed Neo-Nazi who threatened UNF SDS. UNF SDS was able to successfully get\nthe Nazi kicked off campus as well as win many demands for black students such as: increased black enrollment,\nincrease in scholarships and an increase in black faculty and staff. Since then, Monique has fought for a\nsanctuary campus for undocumented students, mental health services for black students, and community\ncontrol of the police. My favorite thing to do to unwind after an action is to watch Christmas movies with my\npartner, dog and cat.', a: $elm$core$Maybe$Nothing, b: 'Monique Sampson'},
		{c: 'Muhammad Sankari is a lead organizer with the Arab American Action Network, and helps guide the AAAN&#39;s\nYouth-lead campaign to end racial profiling.', a: $elm$core$Maybe$Nothing, b: 'Muhammad Sankari'},
		{c: 'hondo t’chikwa is a long time activist in the New Afrikan Independence Movement (NAIM). He has worked for\nthe release of New Afrikan political prisoners &amp; prisoners of war in particular, &amp; all political prisoners in general,\nfor three decades. he works with the Re-Build Collective &amp; the National Jericho Amnesty Movement. he can be\nreached at: info@rebuildcollective.org.', a: $elm$core$Maybe$Nothing, b: 'hondo t\'chikwa'},
		{c: 'Mildred Williamson, has spent her teenage and adult life engaged in the work of social justice\nmovements. Most notably, she was an early member of the original National Alliance Against Racist &amp;amp;\nPolitical Repression, co-led the Chicago Chapter and served as a national board member for many years. Her\ncareer in public service is also framed by human rights/social justice. She has more than 25 years of experience in\ndeveloping and leading public health safety net programs for vulnerable populations. She has held a number of\npositions over the years within the Cook County Health (CCH) system. She was the first administrator of the\nWomen &amp;amp; Children HIV Program, which today, is part of the Ruth M. Rothstein CORE Center, where she\ncurrently serves as Executive Director. She is also an adjunct assistant professor at the University of Illinois at\nChicago School of Public Health (UIC-SPH).', a: $elm$core$Maybe$Nothing, b: 'Mildred Williamson, PhD, MSW'},
		{c: 'Arewa Karen Winters was born and raised on Chicago’s Greater Westside. She is a freedom fighter, activist and\nserves on the frontline for justice around police violence and terrorism. Her organizational affiliations include\nThe 411 Movement for Pierre Loury, Justice for Families, a chapter of Black Lives Matter Chicago and Women’s\nAll Points Bulletin. She is also a spoken word artist and classically trained chef who feels as though it is her duty\nto feed and liberate her people.', a: $elm$core$Maybe$Nothing, b: 'Arewa Karen Winters'},
		{c: 'Danya Zituni is a member of US Palestinian Community Network, a national Arab and Palestinian grassroots\norganization.', a: $elm$core$Maybe$Nothing, b: 'Danya Zituni'},
		{c: 'Christel Williams-Hayes is a 25-year veteran of Chicago Public Schools, serving as a PSRP delegate, district\n      supervisor, functional vice president and American Federation of Teachers-Illinois Federation of Teachers (AFT-IFT)\n      convention delegate in that time. Christel was a Chicago Teachers Union organizer during the 2012 strike, as well as the\n      three-day march in 2013 and CTU Summer Organizing Institute prior to becoming a Union field representative in 2017. She\n      is an IFT executive board vice president, member of the IFT Scholarship Committee and has served as co-chair of the IFT\n      PSRP Constituency Committee and organizer of its bi-annual PSRP Constituency Conference. At the national level, Christel\n      has worked on the PSRP Professional Problems Committee for the American Federation of Teachers and was a member of its\n      Summer Organizing Institute in Houston, Texas. A native of Chicago’s West Side, Christel was raised by a single mother\n      who retired as a school clerk. She graduated from John Marshall High School and proudly serves on the school’s alumni\n      committee. Christel, her husband and her three daughters are all graduates of CPS, with her middle daughter starring for\n      a Marshall Lady Commandos state championship team under legendary coach Dorothy Gaters.', a: $elm$core$Maybe$Nothing, b: 'Danya Zituni'}
	]);
var $author$project$Main$speakersSection = A2(
	$elm$html$Html$section,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$id('speakers')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Speakers')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			A2($elm$core$List$map, $author$project$Main$speakerItem, $author$project$Data$speakers))
		]));
var $author$project$Main$view = function (model) {
	return {
		cl: _List_fromArray(
			[
				$author$project$Main$hero,
				$author$project$Main$navbar,
				A2(
				$elm$html$Html$main_,
				_List_Nil,
				_List_fromArray(
					[$author$project$Main$aboutSection, $author$project$Main$endorsementsSection, $author$project$Main$agendaSection, $author$project$Main$speakersSection, $author$project$Main$attendSection, $author$project$Main$quoteSection, $author$project$Main$logisticsSection])),
				$author$project$Main$footerSection
			]),
		d: $author$project$Data$siteInfo.d
	};
};
var $author$project$Main$main = $elm$browser$Browser$document(
	{
		cU: function (_v0) {
			return $author$project$Main$init;
		},
		dr: $elm$core$Basics$always($elm$core$Platform$Sub$none),
		dL: $author$project$Main$update,
		dM: $author$project$Main$view
	});
_Platform_export({'Main':{'init':$author$project$Main$main(
	$elm$json$Json$Decode$succeed(0))(0)}});}(this));